import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Slider from './Slider';
import Components from '../core/Components';
import Config from '../core/Config';
import { get } from '../lib/ajax';

export default el => {

    const { uniqueId } = el.dataset;
    const similarObjects = document.getElementById('similar-objects');
    const images = el.querySelector('[data-images]');

    const { actionTrigger } = Config.get();

    let slider;

    const getSimilarArtifacts = () => {
        if (!similarObjects) {
            return;
        }
        let url = `/${actionTrigger}/fhm/archive/similar`;

        const searchQueryParams = new URLSearchParams();
        const params = JSON.parse(similarObjects.dataset.params);
        Object.keys(params).forEach(param => {
            searchQueryParams.set(param, params[param]);
        });
        searchQueryParams.set('artifactId', uniqueId);
        url += `?${searchQueryParams.toString()}`;

        get(url)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                return res.text();
            })
            .then(html => {
                const { height } = similarObjects.getBoundingClientRect();
                gsap.timeline()
                    .to(similarObjects, {
                        opacity: 0,
                        duration: 0.3
                    })
                    .add(() => {
                        Components.destroy(similarObjects);
                        similarObjects.innerHTML = html;
                        Components.init(similarObjects);
                    })
                    .to(similarObjects, {
                        opacity: 1,
                        duration: 0.3
                    }, 'in')
                    .fromTo(similarObjects, { height }, {
                        height: 'auto',
                        duration: 0.3,
                        ease: 'Power2.easeInOut'
                    }, 'in');
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                similarObjects.classList.remove('loading');
            });
    };

    const createSlideshow = () => {
        if (slider || !images || images.querySelectorAll('figure').length <= 1) {
            return;
        }

        slider = Slider(images, {
            wrapAround: true,
            setGallerySize: true
        });
        slider.init();
    };

    const init = () => {
        if (images) {
            let ratio = window.getComputedStyle(images).getPropertyValue('--ratio');
            if (ratio) {
                createSlideshow();
            } else {
                images.querySelector('img.lazyload')
                    .addEventListener('lazyloaded', e => {
                        const { naturalWidth: width, naturalHeight: height } = e.target;
                        if (width && height) {
                            ratio = width / height;
                            if (ratio < 0.6) {
                                ratio = 0.6;
                            }
                            images.style.setProperty('--ratio', ratio);
                        } else {
                            images.style.setProperty('--ratio', 4 / 3);
                        }
                        createSlideshow();
                    }, { once: true });
            }
        }

        getSimilarArtifacts();

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (slider) {
            slider.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
