import gsap from 'gsap';
import Components from '../core/Components';
import animatedScroll from '../lib/animatedScroll';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';
import { get } from '../lib/ajax';

export default (el, props = {}) => {

    const button = el.querySelector('a');
    const listing = document.getElementById(props.target);
    const { announcerId, announcements = {} } = props || {};

    if (!listing) {
        console.warn('Listing container not found');
        return null;
    }

    let announcer;
    let abortController;
    let url = button.href;

    const announce = announcement => {
        if (!announcer) {
            return;
        }
        announcer.textContent = announcement;
    };

    const onClick = async e => {
        e.preventDefault();

        if (abortController) {
            abortController.abort();
        }
        abortController = new AbortController();

        announce(announcements.loading || '');

        button.classList.add('loading');

        const response = await get(url, { signal: abortController.signal });
        const { status } = response;

        response.text()
            .then(html => {
                if (status !== 200) {
                    throw new Error(status);
                }
                const template = document.createElement('template');
                template.innerHTML = html;
                const newListing = template.content.querySelector(`#${props.target}`);
                const newButton = newListing.parentNode.querySelector('[data-component="LoadMore"] a');
                if (newButton && newButton.href) {
                    url = newButton.href;
                } else {
                    gsap.timeline({
                        onComplete() {
                            el.hidden = true;
                        }
                    })
                        .to(el, { height: 0, duration: 0.3 }, 0)
                        .to(el, { opacity: 0, duration: 0.3 }, 0);
                }
                const numNewNodes = newListing.children.length;
                const numOldNodes = listing.children.length;
                Array.from(newListing.children).forEach(node => {
                    listing.appendChild(node);
                });
                Components.init(listing);
                Dispatch.emit(DOM_CHANGED);
                const firstNewNode = Array.from(listing.children)[numOldNodes];
                try {
                    if (firstNewNode.tagName === 'a') {
                        firstNewNode.focus({ preventScroll: true });
                    } else {
                        firstNewNode.querySelector('a,button').focus({ preventScroll: true });
                    }
                } catch (error) {}
                animatedScroll(firstNewNode);
                if (announcements.loaded) {
                    announce(announcements.loaded.replace('{count}', numNewNodes));
                } else {
                    announce('');
                }
            })
            .catch(error => {
                console.error(error);
                announce(announcements.error || '');
            })
            .finally(() => {
                abortController = null;
                button.classList.remove('loading');
            });

    };

    const onButtonKeyDown = e => {
        if (e.key === 'Enter' || e.key === ' ') {
            onClick(e);
        }
    };

    const init = () => {
        // Get or create announcer
        announcer = announcerId ? document.getElementById(announcerId) : null;
        if (!announcer) {
            announcer = document.createElement('span');
            announcer.setAttribute('role', 'status');
            announcer.setAttribute('aria-live', 'polite');
            announcer.classList.add('sr-only');
            el.parentNode.appendChild(announcer);
        }
        button.removeAttribute('href');
        button.setAttribute('role', 'button');
        button.setAttribute('tabIndex', '0');
        button.addEventListener('keydown', onButtonKeyDown);
        button.addEventListener('click', onClick);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        button.removeEventListener('keydown', onButtonKeyDown);
        button.removeEventListener('click', onClick);
    };

    return {
        init,
        destroy
    };

};
