import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

import Form from '../modules/Form';

export default form => {

    let formInstance;

    const init = () => {
        formInstance = Form(form);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        formInstance.destroy();
        formInstance = null;
    };

    return {
        init,
        destroy
    };
};
