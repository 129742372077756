import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default select => {

    const onChange = () => {
        if (select.value) {
            select.classList.add('has-value');
        } else {
            select.classList.remove('has-value');
        }
    };

    const init = () => {
        select.addEventListener('change', onChange);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        select.removeEventListener('change', onChange);
    };

    return {
        init,
        destroy
    };

};
