import gsap from 'gsap';
import debounce from 'lodash/debounce';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';
import { URLSearchParams } from 'core-js/modules/web.url-search-params.constructor';
import Components from '../core/Components';
import { get } from '../lib/ajax';

export default (el, props) => {

    const filters = el.querySelector('[data-filters]');
    const resetFiltersBtn = filters ? filters.querySelector('button[data-reset]') : null;
    const listing = el.querySelector('[data-listing]');

    const { announcements = {} } = props || {};
    const announcer = document.getElementById('announcer');

    let abortController;

    const announce = announcement => {
        if (!announcer) {
            return;
        }
        announcer.textContent = announcement;
    };

    const updateListing = async () => {
        const url = window.location.href;

        if (abortController) {
            abortController.abort();
        }
        abortController = new AbortController();

        announce(announcements.loading || '');

        el.classList.add('loading');

        const response = await get(url, { signal: abortController.signal });
        const { status } = response;

        response.text()
            .then(html => {
                if (status !== 200) {
                    throw new Error(status);
                }
                const template = document.createElement('template');
                template.innerHTML = html;
                const newListing = template.content.querySelector('[data-listing]');
                const numTeasers = newListing.querySelectorAll('[data-teaser]').length;
                const { height: listingFromHeight } = listing.getBoundingClientRect();
                gsap.timeline()
                    .to(listing, { opacity: 0, duration: 0.3 })
                    .add(() => {
                        Components.destroy(listing);
                        listing.innerHTML = newListing.innerHTML;
                        Components.init(listing);
                        Dispatch.emit(DOM_CHANGED);
                    })
                    .to(listing, { opacity: 1, duration: 0.3, ease: 'Cubic.easeIn' }, 'in')
                    .fromTo(listing, { height: listingFromHeight }, { height: 'auto', duration: 0.3, ease: 'Power2.easeOut' }, 'in');
                if (announcer) {
                    if (announcements.loaded) {
                        announce(announcements.loaded.replace('{count}', numTeasers)); // numNewNodes
                    } else {
                        announce('');
                    }
                }
            })
            .catch(error => {
                announce(announcements.error || '');
                console.error(error);
            })
            .finally(() => {
                abortController = null;
                el.classList.remove('loading');
                el.querySelectorAll('.loading').forEach(node => node.classList.remove('loading'));
            });
    };

    const onFiltersChange = () => {
        const activeFilters = [...filters.querySelectorAll('input[type="hidden"]')].reduce((carry, input) => {
            const {
                name,
                value
            } = input;
            if (!value || value === '*') {
                return carry;
            }
            return {
                ...carry,
                [name]: value
            };
        }, {});
        const hasFilters = !!Object.keys(activeFilters).length;
        if (hasFilters) {
            resetFiltersBtn.hidden = false;
            gsap.timeline()
                .to(resetFiltersBtn, {
                    opacity: 1,
                    duration: 0.15
                });
        } else {
            gsap.to(resetFiltersBtn, {
                opacity: 0,
                duration: 0.15,
                onComplete() {
                    resetFiltersBtn.hidden = true;
                }
            });
        }
        // Create query string
        let url = window.location.pathname;
        if (hasFilters) {
            const params = new URLSearchParams();
            Object.keys(activeFilters).forEach(param => {
                params.set(param, activeFilters[param]);
            });
            url = `${url}?${params.toString()}`;
        }
        window.history.replaceState(null, '', url);
        updateListing();
    };

    const onFiltersChangeDebounced = debounce(onFiltersChange, 100);

    const onResetFiltersBtnClick = () => {
        const listboxEls = el.querySelectorAll('[data-component="Listbox"]');
        if (!listboxEls.length) {
            return;
        }
        listboxEls.forEach(listboxEl => {
            const { _listbox: listbox } = listboxEl;
            listbox.reset();
        });

        listboxEls[0].querySelector('button').focus({ preventScroll: true });
    };

    const init = () => {
        if (filters) {
            filters.addEventListener('change', onFiltersChangeDebounced);
        }

        if (resetFiltersBtn) {
            resetFiltersBtn.addEventListener('click', onResetFiltersBtnClick);
            if (resetFiltersBtn.hidden) {
                gsap.set(resetFiltersBtn, { opacity: 0 });
            }
        }

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (filters) {
            filters.removeEventListener('change', onFiltersChangeDebounced);
        }
        if (resetFiltersBtn) {
            resetFiltersBtn.removeEventListener('click', onResetFiltersBtnClick);
        }
    };

    return {
        init,
        destroy
    };

};
