import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {
    const museumsListbox = document.getElementById('museums');
    const buyButton = el.querySelector('a[data-buy]');

    const onMuseumsListboxChange = () => {
        const selectedOption = museumsListbox._listbox.getSelectedOption();
        const { ticketurl: ticketUrl = '', value = null } = selectedOption ? selectedOption.dataset : {};

        // Update buy button href and show/hide it
        buyButton.href = ticketUrl;
        if (ticketUrl && buyButton.getAttribute('aria-disabled') === 'true') {
            buyButton.removeAttribute('aria-disabled');
            buyButton.removeAttribute('tabindex');
            gsap.timeline().to(buyButton, { opacity: 1, duration: 0.3 }, 0);
        } else if (!ticketUrl && buyButton.getAttribute('aria-disabled') !== 'true') {
            buyButton.setAttribute('aria-disabled', 'true');
            buyButton.setAttribute('tabindex', '-1');
            buyButton.removeAttribute('href');
            gsap.timeline().to(buyButton, { opacity: 0, duration: 0.3 }, 0);
        }

        // Update URL
        // let url = window.location.pathname;
        // if (value) {
        //     const params = new URLSearchParams();
        //     params.set('museum', value);
        //     url = `${url}?${params.toString()}`;
        // }
        // window.history.replaceState(null, '', url);
    };

    const init = () => {
        museumsListbox.addEventListener('change', onMuseumsListboxChange);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        museumsListbox.removeEventListener('change', onMuseumsListboxChange);
    };

    return {
        init,
        destroy
    };

};
