import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, REDUCED_MOTION_CHANGE } from '../lib/events';
import { clearTimelineProps, reduceMotionQuery } from '../lib/helpers';
import Viewport from '../core/Viewport';

export default el => {

    const images = [...el.querySelectorAll('[data-image]')];
    const text = el.querySelector('[data-text]');

    let tl;
    let width;
    let height;
    let isMotionReduced;

    const isDesktop = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) > -1;

    const killTimeline = () => {
        if (!tl) {
            return;
        }

        gsap.killTweensOf(tl);
        clearTimelineProps(tl);
        tl.invalidate();
        tl.kill();
        tl = null;
    };

    const createTimeline = () => {
        killTimeline();

        if (isMotionReduced) {
            return;
        }

        tl = gsap.timeline({ paused: true });

        if (isDesktop()) {
            const mosaicRect = el.getBoundingClientRect();
            const mosaicCenterX = mosaicRect.left + (mosaicRect.width * 0.5);
            const mosaicCenterY = mosaicRect.top + (mosaicRect.height * 0.5);

            images.forEach(image => {
                const imageRect = image.getBoundingClientRect();
                const imageCenterX = imageRect.left + (imageRect.width / 2);
                const imageCenterY = imageRect.top + (imageRect.height / 2);
                const offsetX = (mosaicCenterX - imageCenterX) * 0.5;
                const offsetY = (mosaicCenterY - imageCenterY) * 0.5;

                tl.fromTo(image, {
                    x: offsetX,
                    y: offsetY
                }, {
                    x: 0,
                    y: 0,
                    duration: 1,
                    ease: 'Power1.easeInOut'
                }, 0);
            });
            tl.fromTo(text, { y: '15vmin' }, {
                y: 0,
                duration: 1,
                ease: 'none'
            }, 0);
            tl.fromTo(el.firstElementChild, { y: '-15vmin' }, {
                y: 0,
                duration: 1,
                ease: 'none'
            }, 0);

        } else {

            if (images[0]) {
                tl.fromTo(images[0], {
                    yPercent: 35
                }, {
                    yPercent: 0,
                    duration: 1,
                    ease: 'none'
                }, 0);
            }

            if (images[1]) {
                tl.fromTo(images[1], {
                    yPercent: 55
                }, {
                    yPercent: 0,
                    duration: 1,
                    ease: 'none'
                }, 0);
            }

            if (images[2]) {
                tl.fromTo(images[2], {
                    yPercent: -50
                }, {
                    yPercent: 0,
                    duration: 1,
                    ease: 'none'
                }, 0);
            }

            if (images[3]) {
                tl.fromTo(images[3], {
                    yPercent: -100
                }, {
                    yPercent: 0,
                    duration: 1,
                    ease: 'none'
                }, 0);
            }

            if (images[4]) {
                tl.fromTo(images[4], {
                    yPercent: -150
                }, {
                    yPercent: 0,
                    duration: 1,
                    ease: 'none'
                }, 0);
            }
        }
    };

    const updateProgress = (immediate = false) => {
        if (!tl) {
            return;
        }

        const { height: viewportHeight } = Viewport; // TODO replace this with 100svh somehow.
        const { top } = el.getBoundingClientRect();

        let progress;
        if (isDesktop()) {
            progress = Math.max(0, Math.min(1, ((top - (viewportHeight * 0.75)) * -1) / height));
        } else {
            progress = Math.max(0, Math.min(1, ((top - (viewportHeight * 1.25)) * -1) / (height + (viewportHeight * 0.5))));
        }

        if (immediate) {
            gsap.set(tl, { progress });
        } else {
            gsap.to(tl, {
                progress,
                duration: 1,
                ease: 'Quint.easeOut'
            });
        }
    };

    let raf;

    const onScroll = () => {
        if (raf) {
            cancelAnimationFrame(raf);
            raf = null;
        }
        if (!tl) {
            return;
        }
        raf = requestAnimationFrame(() => {
            raf = null;
            updateProgress();
        });
    };

    const onResize = () => {
        const {
            width: newWidth,
            height: newHeight
        } = el.getBoundingClientRect();
        if (newWidth === width && newHeight === height) {
            return;
        }
        width = newWidth;
        height = newHeight;
        createTimeline();
        updateProgress(true);
    };

    const onFocus = () => {
        if (!document.documentElement.classList.contains('outline') || document.activeElement === text) {
            return;
        }

        el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    };

    const onReduceMotionChange = (key, motionReduced) => {
        isMotionReduced = motionReduced;
        if (isMotionReduced) {
            killTimeline();
        } else {
            createTimeline();
        }
    };

    const init = () => {
        isMotionReduced = !!reduceMotionQuery.matches;

        onResize();

        document.addEventListener('scroll', onScroll);

        Viewport.on('resize', onResize);

        gsap.set(el, { opacity: 1 });

        el.addEventListener('focusin', onFocus);

        Dispatch.on(REDUCED_MOTION_CHANGE, onReduceMotionChange);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        document.removeEventListener('scroll', onScroll);
        Viewport.off('resize', onResize);
        Dispatch.off(REDUCED_MOTION_CHANGE, onReduceMotionChange);
        el.removeEventListener('focusin', onFocus);
        killTimeline();
    };

    return {
        init,
        destroy
    };

};
