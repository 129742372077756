import { loadFlickity } from '../lib/async-bundles';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props = {}) => {

    const slider = el.querySelector('[data-slider]') || el;
    const index = el.querySelector('[data-index]');
    const total = el.querySelector('[data-total]');
    const prevBtn = el.querySelector('button[data-nav="prev"]');
    const nextBtn = el.querySelector('button[data-nav="next"]');
    const dots = el.querySelector('[data-dots]');

    let observer;

    let flkty;
    let isSlideable;

    let Flickity;

    const updateIsSlideable = () => {
        if (flkty) {
            const { size, slideableWidth } = flkty;

            isSlideable = Math.round(size.width) < Math.round(slideableWidth);
        } else {
            isSlideable = false;
        }

        if (isSlideable) {
            el.classList.add('is-slideable');
        } else {
            el.classList.remove('is-slideable');
        }
    };

    const updateDots = () => {
        if (!dots || !flkty) {
            return;
        }
        dots.querySelector('.active')
            .classList
            .remove('active');
        Array.from(dots.children)[flkty.selectedIndex].classList.add('active');
    };

    const updateCounter = () => {
        if (!flkty) {
            return;
        }
        if (index) {
            let indexValue;
            if (flkty.options.groupCells) {
                const selectedCell = flkty.selectedSlide.cells[flkty.selectedSlide.cells.length - 1];
                indexValue = flkty.cells.indexOf(selectedCell) + 1;
            } else {
                indexValue = flkty.selectedIndex + 1;
            }
            if (indexValue < 10) {
                indexValue = `0${indexValue}`;
            }
            index.textContent = indexValue;
        }
        if (total) {
            let totalValue = flkty.cells.length;
            if (totalValue < 10) {
                totalValue = `0${totalValue}`;
            }
            total.textContent = totalValue;
        }
    };

    const updateNav = () => {
        if ((!prevBtn && !nextBtn) || !flkty) {
            return;
        }

        const { options, selectedIndex } = flkty;

        if (isSlideable) {
            el.classList.add('is-slideable');
        } else {
            el.classList.remove('is-slideable');
        }

        if (prevBtn) {
            if (!isSlideable || (!options.wrapAround && selectedIndex <= 0)) {
                prevBtn.setAttribute('aria-disabled', 'true');
                prevBtn.setAttribute('tabindex', '-1');
            } else {
                prevBtn.removeAttribute('aria-disabled');
                prevBtn.removeAttribute('tabindex');
            }
        }
        if (nextBtn) {
            if (!isSlideable || (!options.wrapAround && selectedIndex >= flkty.slides.length - 1)) {
                nextBtn.setAttribute('aria-disabled', 'true');
                nextBtn.setAttribute('tabindex', '-1');
            } else {
                nextBtn.removeAttribute('aria-disabled');
                nextBtn.removeAttribute('tabindex');
            }
        }
    };

    const preloadNextSlideImages = () => {
        if (!flkty) {
            return;
        }
        const nextSlide = flkty.slides[flkty.selectedIndex + 1] || null;
        if (!nextSlide) {
            return;
        }
        nextSlide.cells.forEach(cell => {
            cell.element.querySelectorAll('img.lazyload:not(.lazyloaded):not(.lazypreload)').forEach(img => {
                img.classList.add('lazypreload');
            });
        });
    };

    const updateUiElements = () => {
        updateDots();
        updateCounter();
        updateNav();
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $(el)
            .off('click');
        $(slider)
            .off('focusin click');
        updateIsSlideable();
    };

    let isDraggingTimer = null;

    const createFlickity = () => {

        destroyFlickity();

        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            groupCells: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            selectedAttraction: 0.015,
            friction: 0.22,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: false,
            wrapAround: false,
            accessibility: true,
            percentPosition: false,
            cellAlign: 'left',
            on: {
                resize() {
                    updateIsSlideable();
                    updateUiElements();
                },
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                    this.slider.querySelectorAll('a,button')
                        .forEach(link => {
                            link.style.pointerEvents = 'none';
                        });
                    if (isDraggingTimer) {
                        clearTimeout(isDraggingTimer);
                    }
                    this.element.classList.add('is-dragging');
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                    this.slider.querySelectorAll('a,button')
                        .forEach(link => {
                            link.style.pointerEvents = '';
                        });
                    if (isDraggingTimer) {
                        clearTimeout(isDraggingTimer);
                    }
                    isDraggingTimer = setTimeout(() => {
                        clearTimeout(isDraggingTimer);
                        isDraggingTimer = null;
                        this.element.classList.remove('is-dragging');
                    }, 500);
                },
                select() {
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                    updateUiElements();
                },
                settle() {
                    preloadNextSlideImages();
                },
                ready() {
                    el.classList.add('has-flickity');
                    setTimeout(() => {
                        this.resize();
                        preloadNextSlideImages();
                    }, 0);
                }
            },
            ...props
        });

        $(slider)
            .on('focusin', 'a', e => {
                slider.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const cellIndex = flkty.cells.indexOf(cell);
                if (cellIndex > -1) {
                    flkty.selectCell(cellIndex, flkty.options.wrapAround, false);
                }
            });

        $(el)
            .on('click', '[data-nav="prev"]', () => {
                flkty.previous();
            });

        $(el)
            .on('click', '[data-nav="next"]', () => {
                flkty.next();
            });

    };

    const onResize = () => {
        if (!flkty) {
            return;
        }
        setTimeout(() => {
            flkty.resize();
        }, 0);
    };

    const onBreakpoint = () => {
        createFlickity();
    };

    const onDotClick = e => {
        if (!flkty) {
            return;
        }
        flkty.select(parseInt(e.currentTarget.dataset.dot, 10));
        flkty.stopPlayer();
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        if (flkty) {
            destroyFlickity();
            Viewport.off('resize', onResize);
            Viewport.off('breakpoint', onBreakpoint);
        }
        el.querySelectorAll('button[data-dot]')
            .forEach(dot => {
                dot.removeEventListener('click', onDotClick);
            });

        if (isDraggingTimer) {
            clearTimeout(isDraggingTimer);
            isDraggingTimer = null;
        }
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            observer.disconnect();
            observer = null;
            loadFlickity(module => {
                Flickity = module.default;
                requestAnimationFrame(() => {
                    createFlickity();
                });
            });
            Viewport.on('resize', onResize);
            Viewport.on('breakpoint', onBreakpoint);
        });
        observer.observe(el);

        el.querySelectorAll('button[data-dot]')
            .forEach(dot => {
                dot.addEventListener('click', onDotClick);
            });

        Dispatch.emit(COMPONENT_INIT);
    };

    return {
        init,
        destroy
    };

};
