import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import Revealer from './lib/Revealer';
import Transitioner from './lib/Transitioner';
import { plausible, isLivePreview, reduceMotionQuery } from './lib/helpers';
import { REDUCED_MOTION_CHANGE } from './lib/events';
import Dispatch from './core/Dispatch';
import animatedScroll from './lib/animatedScroll';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    const onReduceMotionChange = () => {
        if (!reduceMotionQuery.matches) {
            if (!isLivePreview()) {
                Revealer.init();
                Transitioner.init();
            }
        } else {
            Revealer.destroy();
            Transitioner.destroy();
        }
        Dispatch.emit(REDUCED_MOTION_CHANGE, !!reduceMotionQuery.matches);
    };

    onReduceMotionChange();

    reduceMotionQuery.onchange = onReduceMotionChange;

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();

    window.addEventListener('popstate', () => {
        window.location.reload();
    });

    // Track clicks on buttons and links to Plausible
    $('body').on('click', 'a[data-plausible-event],button[data-plausible-event]', e => {
        const { triggerTarget: target } = e;
        if (target.getAttribute('aria-disabled') === 'true' || !target.dataset.plausibleEvent) {
            return;
        }
        plausible.customEvent(target.dataset.plausibleEvent);
    });

    $('body').on('click', 'a[href^="#"]', e => {
        const targetId = e.target.getAttribute('href').slice(1);
        const target = document.getElementById(targetId);
        if (!target) {
            console.warn(`Target ${targetId} not found`);
            e.preventDefault();
        }
        e.preventDefault();
        animatedScroll(target);
        return false;
    });

    let scrollTimer;
    window.addEventListener('scroll', () => {
        clearTimeout(scrollTimer);
        document.body.classList.add('disable-hover');

        scrollTimer = setTimeout(() => {
            document.body.classList.remove('disable-hover');
        }, 50);
    }, false);
};

require('doc-ready')(init);
