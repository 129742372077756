import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';

export default el => {

    let hasVisualLoaded = false;

    const onVisualLoaded = () => {
        if (hasVisualLoaded) {
            return;
        }

        hasVisualLoaded = true;

        el.removeEventListener('lazyloaded', onVisualLoaded);
        el.removeEventListener('video_loop_playing', onVisualLoaded);

        el.querySelectorAll('[data-x-reveal]').forEach(node => {
            node.setAttribute('data-reveal', node.getAttribute('data-x-reveal'));
            node.removeAttribute('data-x-reveal');

            Dispatch.emit(DOM_CHANGED);
        });
    };

    const init = () => {
        if (el.querySelector('img.lazyloaded') || el.querySelector('video[data-is-playing]')) {
            onVisualLoaded();
        } else {
            el.addEventListener('lazyloaded', onVisualLoaded);
            el.addEventListener('video_loop_playing', onVisualLoaded);
        }

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (!hasVisualLoaded) {
            el.removeEventListener('lazyloaded', onVisualLoaded);
            el.removeEventListener('video_loop_playing', onVisualLoaded);
        }
    };

    return {
        init,
        destroy
    };

};
