import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const museumSelect = el.querySelector('select[name$="[museum]"]');
    const tourSelect = el.querySelector('select[name$="[tour]"]');

    const onMuseumChange = () => {
        tourSelect.value = '';
        tourSelect.querySelectorAll('option:not(:first-child)').forEach(tourOption => {
            tourOption.remove();
        });

        const { value: museum } = museumSelect;

        if (museum) {
            const museumOption = museumSelect.querySelector(`option[value="${museum}"]`);
            const tourOptions = JSON.parse(museumOption.dataset.tours);

            tourOptions.forEach(tourOption => {
                const tourOptionEl = document.createElement('option');
                tourOptionEl.setAttribute('value', tourOption.value);
                tourOptionEl.textContent = tourOption.label;
                tourSelect.appendChild(tourOptionEl);
            });
        }

        if (tourSelect.querySelectorAll('option').length > 1) {
            tourSelect.removeAttribute('aria-disabled');
        } else {
            tourSelect.setAttribute('aria-disabled', 'true');
        }
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);

        if (museumSelect) {
            museumSelect.addEventListener('change', onMuseumChange);
        }
    };

    const destroy = () => {
        if (museumSelect) {
            museumSelect.removeEventListener('change', onMuseumChange);
        }
    };

    return {
        init,
        destroy
    };

};
