import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, HEADER_STICK, HEADER_UNSTICK, MENU_CLOSED, MENU_CLOSING, UNLOAD } from '../lib/events';
import { clearTimelineProps, reduceMotionQuery } from '../lib/helpers';
import Viewport from '../core/Viewport';

export default (button, props) => {

    const { autoClose = false, focusFirstInput = false, animate = true } = props || {};
    const targetId = button.getAttribute('aria-controls');
    const target = document.getElementById(targetId);

    if (!target) {
        console.warn(`Disclosure button target "${targetId}" not found`);
        return null;
    }

    const targetBackdrop = document.querySelector(`[data-backdrop="${targetId}"]`);
    const useAttribute = !target.classList.contains('hidden');

    let tl;

    const isExpanded = () => {
        if (useAttribute) {
            return !target.hidden;
        }
        return !target.classList.contains('hidden');
    };

    const killTl = () => {
        if (!tl) {
            return;
        }
        clearTimelineProps(tl);
        tl = null;
    };

    const createTl = () => {
        if (tl) {
            return;
        }
        tl = gsap.timeline({
            onReverseComplete() {
                killTl();
                if (useAttribute) {
                    target.hidden = true;
                } else {
                    target.classList.add('hidden');
                }
                if (targetBackdrop) {
                    targetBackdrop.hidden = true;
                }
                button.dispatchEvent(new Event('change', { bubbles: true }));
            }
        })
            .fromTo(target, { height: 0 }, { height: 'auto', duration: 0.5, ease: 'Power2.easeInOut' }, 0);
        if (target.children.length) {
            tl
                .fromTo(target.children, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeInOut', stagger: 0.1 }, 0);
        }
        if (targetBackdrop) {
            tl
                .fromTo(targetBackdrop, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
        }
        tl.set(target, { clearProps: 'all' });
    };

    const expand = () => {
        if (isExpanded()) {
            return;
        }
        document.body.querySelectorAll(`button[aria-controls="${target.id}"]`).forEach(toggle => {
            toggle.setAttribute('aria-expanded', 'true');
        });
        button.dispatchEvent(new Event('change', { bubbles: true }));
        if (useAttribute) {
            target.hidden = false;
        } else {
            target.classList.remove('hidden');
        }
        if (targetBackdrop) {
            targetBackdrop.hidden = false;
        }
        if (animate && !reduceMotionQuery.matches) {
            createTl();
            tl.play();
        } else {
            killTl();
        }
        if (focusFirstInput) {
            const firstFocusable = target.querySelector('input:not([type="hidden"]):not([disabled]),button');
            if (firstFocusable) {
                firstFocusable.focus({ preventScroll: true });
            }
        }
    };

    const collapse = (tween = true) => {
        if (!isExpanded()) {
            return;
        }
        document.body.querySelectorAll(`button[aria-controls="${target.id}"]`).forEach(toggle => {
            toggle.setAttribute('aria-expanded', 'false');
        });
        if (tween && animate && tl && !reduceMotionQuery.matches) {
            tl.reverse();
        } else {
            killTl();
            if (useAttribute) {
                target.hidden = true;
            } else {
                target.classList.add('hidden');
            }
            button.dispatchEvent(new Event('change', { bubbles: true }));
        }
        if (autoClose && (!document.activeElement || document.activeElement === document.body || document.activeElement.offsetParent === null)) {
            button.focus({ preventScroll: true });
        }
    };

    const onClick = () => {
        if (isExpanded()) {
            collapse();
        } else {
            expand();
        }
    };

    const onBodyClickFocus = e => {
        if (!isExpanded()) {
            return;
        }
        if ((e.target === button || button.contains(e.target) || e.target === target || target.contains(e.target)) && e.target.getAttribute('data-backdrop') !== targetId) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded() || e.key !== 'Escape') {
            return;
        }
        e.stopPropagation();
        collapse();
    };

    const onMenuClosed = () => {
        collapse(false);
    };

    const onUnload = () => {
        collapse();
    };

    const onResize = () => {
        if (!isExpanded()) {
            return;
        }

        killTl();
        createTl();
        tl.pause(tl.totalDuration());
    };

    const init = () => {
        button.addEventListener('click', onClick);
        if (autoClose) {
            document.body.addEventListener('click', onBodyClickFocus);
            document.body.addEventListener('focusin', onBodyClickFocus);
            document.body.addEventListener('keyup', onBodyKeyUp);

            Dispatch.on(UNLOAD, onUnload);
        }

        target.setAttribute('data-disclosure-target', true);
        target.querySelectorAll(`button[aria-controls="${target.id}"]`).forEach(closeBtn => {
            closeBtn.addEventListener('click', collapse);
        });

        if (button.closest('#menu')) {
            // We're inside the main menu
            Dispatch.on(MENU_CLOSED, onMenuClosed);
        }

        Viewport.on('resize', onResize);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        collapse(false);
        button.removeEventListener('click', onClick);
        if (autoClose) {
            document.body.removeEventListener('click', onBodyClickFocus);
            document.body.removeEventListener('focusin', onBodyClickFocus);
            document.body.removeEventListener('keyup', onBodyKeyUp);

            Dispatch.off(UNLOAD, onUnload);
        }

        target.querySelectorAll(`button[aria-controls="${target.id}"]`).forEach(closeBtn => {
            closeBtn.removeEventListener('click', collapse);
        });

        if (button.closest('#menu')) {
            // We're inside the main menu
            Dispatch.off(MENU_CLOSED, onMenuClosed);
        }

        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
