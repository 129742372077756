import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { post } from '../lib/ajax';
import { t } from '../lib/helpers';

export default (form, options) => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const submitBtn = $form.find('button[type="submit"]').get(0);

    const announcer = $form.find('[data-announcer]').get(0);

    const messages = JSON.parse(form.dataset.announcements || '{}');

    const { onSuccess } = options || {};

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    // Clear input fields
    const clearInputs = () => {
        $form.find('input:not([type="hidden"]),textarea,select').get().forEach(input => {
            if (input.nodeName === 'SELECT') {
                input.selectedIndex = 0;
            } else if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                input.checked = false;
            } else {
                input.value = null;
            }
            try {
                input.dispatchEvent(new Event('change'));
            } catch (error) {
                console.error(error);
            }
        });
    };

    const showGenericError = message => {
        errorMessage.hidden = false;
        errorMessage.firstElementChild.innerHTML = message;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
            errorMessage.firstElementChild.innerHTML = '';
        }
        $form.find('[data-errors]').each(node => {
            node.firstElementChild.innerHTML = '';
            node.hidden = true;
        });
        $form.find('[data-input]').removeClass('has-errors');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('has-errors');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                errorsDiv.firstElementChild.innerHTML = [].concat(errorsByName[name]).join('<br/>');
            }
        });

        const firstErrorInput = $form.find('[data-input].has-errors').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
            } catch (error) {
                // Don't care.
            }
        }

        enableSubmit();

    };

    const hideReceipt = () => {
        const receipt = form.querySelector('[data-receipt]');
        if (!receipt) {
            return;
        }
        receipt.hidden = true;
        const closeReceiptBtn = receipt.querySelector(`button[aria-controls="${receipt.id}"]`);
        if (closeReceiptBtn) {
            closeReceiptBtn.setAttribute('aria-expanded', 'false');
            closeReceiptBtn.removeEventListener('click', hideReceipt);
        }
        if (!document.activeElement || receipt.contains(document.activeElement)) {
            try {
                form.querySelector('input:not([type="hidden"]),button').focus({ preventScroll: true });
            } catch (error) {
                console.error(error);
            }
        }
    };

    const showReceipt = () => {
        const receipt = form.querySelector('[data-receipt]');
        if (!receipt) {
            return;
        }
        receipt.hidden = false;
        const closeReceiptBtn = receipt.querySelector(`button[aria-controls="${receipt.id}"]`);
        if (closeReceiptBtn) {
            closeReceiptBtn.focus();
            closeReceiptBtn.setAttribute('aria-expanded', 'true');
            closeReceiptBtn.addEventListener('click', hideReceipt);
        }
    };

    const onSubmit = async e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        isSubmitting = true;
        disableSubmit();

        submitBtn.classList.add('loading');

        if (announcer) {
            announcer.textContent = messages.submitting || '';
        }

        const url = window.location.href.split('?')[0].split('#')[0];

        const response = await post(url, { body: new FormData($form.get(0)) });

        const { status } = response;

        response.json()
            .then(res => {
                clearErrors();
                if (status < 400) {
                    if (announcer) {
                        announcer.textContent = messages.submitted || '';
                    }
                    clearInputs();
                    if (onSuccess) {
                        onSuccess(res);
                        return;
                    }
                    const { redirect } = res;
                    if (redirect) {
                        if (redirect.startsWith('http')) {
                            window.location.href = res.redirect;
                        } else {
                            window.location.href = `/${res.redirect}`;
                        }
                    } else {
                        showReceipt();
                    }
                } else if (res.errors) {
                    if (announcer) {
                        announcer.textContent = messages.errors || '';
                    }
                    showErrors(res.errors);
                } else {
                    if (announcer) {
                        announcer.textContent = messages.failure || '';
                    }
                    showGenericError(res.message || t('form.error'));
                }
            })
            .finally(() => {
                isSubmitting = false;
                submitBtn.classList.remove('loading');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').attr('target', '_blank');
        $('body').find('[data-colophone] a:not([href^="#"])').attr('target', '_blank');

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    init();

    return {
        destroy
    };
};
