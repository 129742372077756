import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, MENU_CLOSED, MENU_CLOSING, MENU_OPENED, MENU_OPENING, UNLOAD } from '../lib/events';
import { clearTimelineProps, reduceMotionQuery, rgbToRgba } from '../lib/helpers';
import Viewport from '../core/Viewport';

export default el => {

    const burger = el.querySelector('button[aria-controls="menu"]');
    const menu = document.getElementById('menu');
    const items = menu.querySelectorAll('[data-items]');

    let menuIsOpen = false;
    let tl;
    let burgerTl;

    const killTl = () => {
        if (!tl) {
            return;
        }
        clearTimelineProps(tl);
        tl.kill();
        tl = null;
    };

    const openMenu = (tween = true) => {
        if (menuIsOpen) {
            return;
        }
        menuIsOpen = true;
        menu.hidden = false;
        burger.setAttribute('aria-expanded', 'true');
        document.body.classList.add('menu-open');
        document.body.classList.remove('menu-closing');

        Dispatch.emit(MENU_OPENING);

        killTl();

        if (!tween) {
            burgerTl.pause(burgerTl.totalDuration());
            Dispatch.emit(MENU_OPENED);
            return;
        }

        tl = gsap.timeline({
            onComplete() {
                killTl();
                Dispatch.emit(MENU_OPENED);
            }
        })
            .fromTo(menu, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0);

        if (!reduceMotionQuery.matches) {
            tl
                .fromTo(items, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3,
                    ease: 'Cubic.easeIn'
                }, 0)
                .fromTo(items, { y: 25 }, {
                    y: 0,
                    duration: 1.5,
                    ease: 'Quint.easeOut'
                }, 0);
        }

        const borderDivs = [...menu.querySelectorAll('[data-border]')];
        if (borderDivs.length && borderDivs[0].offsetParent !== null) {
            tl
                .fromTo(borderDivs, { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3
                }, 0)
                .fromTo(borderDivs, { scaleX: 0 }, {
                    scaleX: 1,
                    duration: 0.5,
                    ease: 'Power2.easeInOut'
                }, 0);
        }

        burgerTl.play();

    };

    const afterClose = () => {
        killTl();
        menu.querySelector('.scrollable')
            .scrollTo(0, 0);
        menu.hidden = true;
        document.body.classList.remove('menu-closing');
        Dispatch.emit(MENU_CLOSED);
    };

    const closeMenu = (tween = true) => {
        if (!menuIsOpen) {
            return;
        }
        menuIsOpen = false;

        burger.setAttribute('aria-expanded', 'false');
        document.body.classList.remove('menu-open');
        document.body.classList.add('menu-closing');

        Dispatch.emit(MENU_CLOSING);

        killTl();

        if (!tween) {
            burgerTl.pause(0);
            afterClose();
            return;
        }

        tl = gsap.timeline({
            onComplete: afterClose
        })
            .to(menu, {
                opacity: 0,
                duration: 0.3
            }, 0);

        burgerTl.reverse();

    };

    const toggleMenu = () => {
        if (menuIsOpen) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    const onBodyKeyUp = e => {
        if (!menuIsOpen || e.key !== 'Escape') {
            return;
        }
        closeMenu();
    };

    const onBodyClick = e => {
        if (!menuIsOpen || e.target === menu.firstElementChild || menu.firstElementChild.contains(e.target) || e.target === burger || burger.contains(e.target)) {
            return;
        }
        closeMenu();
    };

    // Focus trap
    const onKeyDown = e => {
        if (!menuIsOpen || (e.key !== 'Tab' && e.keyCode !== 9)) {
            return;
        }
        const firstFocusable = burger;
        const lastFocusable = Array.from(el.querySelectorAll('a,button'))
            .pop() || firstFocusable;
        if (e.shiftKey && document.activeElement === firstFocusable) {
            e.preventDefault();
            lastFocusable.focus();
        } else if (!e.shiftKey && document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
        }
    };

    const onUnload = () => {
        closeMenu();
    };

    const onBurgerClick = () => {
        toggleMenu();
    };

    const init = () => {
        burger.addEventListener('click', onBurgerClick);
        el.addEventListener('keydown', onKeyDown);
        document.body.addEventListener('click', onBodyClick);
        document.body.addEventListener('keyup', onBodyKeyUp);
        Dispatch.on(UNLOAD, onUnload);

        const buns = [...burger.querySelectorAll('[data-bun]')];
        const patty = burger.querySelector('[data-patty]');
        burgerTl = gsap.timeline({ paused: true })
            .to(buns[0], {
                y: 11,
                duration: 0.3,
                ease: 'Back.easeIn'
            }, 'smash')
            .to(patty, {
                scaleX: 0,
                duration: 0.3,
                ease: 'Back.easeIn'
            }, 'smash')
            .to(buns[1], {
                y: -11,
                duration: 0.3,
                ease: 'Back.easeIn'
            }, 'smash')
            .to(buns[0], {
                rotate: -45,
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 'flip')
            .to(buns[1], {
                rotate: 45,
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 'flip');

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        burger.removeEventListener('click', onBurgerClick);
        el.removeEventListener('keydown', onKeyDown);
        document.body.removeEventListener('click', onBodyClick);
        document.body.removeEventListener('keyup', onBodyKeyUp);
        Dispatch.off(UNLOAD, onUnload);
        closeMenu(false);

        if (burgerTl) {
            burgerTl.kill();
            burgerTl = null;
        }
    };

    return {
        init,
        destroy
    };

};
