import gsap from 'gsap';
import debounce from 'lodash/debounce';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { post } from '../lib/ajax';
import Config from '../core/Config';
import Components from '../core/Components';

export default (el, props) => {

    const listing = document.getElementById('listing');
    const filters = document.getElementById('filters');
    const searchField = filters.querySelector('input[name="query"]');
    const museumsListbox = document.getElementById('museum');
    const queryParams = new URLSearchParams(window.location.search);
    const resetFiltersBtn = el.querySelector('button[data-reset]');

    const { announcements = {} } = props || {};
    const announcer = document.getElementById('announcer');

    const { actionTrigger } = Config.get();

    let page = parseInt(queryParams.get('page') || 1, 10);
    let museum = queryParams.get('museum') || '*';
    let subjects = queryParams.get('subjects') || null;
    let searchQuery = searchField.value.trim();
    let prevLocation = null;
    let abortController;

    const announce = announcement => {
        if (!announcer) {
            return;
        }
        announcer.textContent = announcement;
    };

    const cleanQuery = input => {
        let result = input.replace(/[^\p{L}\p{N}\s]/gu, '');

        // Replace multiple spaces with a single space
        result = result.replace(/\s+/g, ' ').trim();

        return result;
    };

    const showResetButton = () => {
        if (!resetFiltersBtn.hidden) {
            return;
        }
        resetFiltersBtn.hidden = false;
        gsap.timeline()
            .to(resetFiltersBtn, {
                opacity: 1,
                duration: 0.15
            });
    };

    const hideResetButton = () => {
        if (resetFiltersBtn.hidden) {
            return;
        }
        gsap.to(resetFiltersBtn, {
            opacity: 0,
            duration: 0.15,
            onComplete() {
                resetFiltersBtn.hidden = true;
            }
        });
    };

    const removeSubjects = () => {
        const subjectsEl = el.querySelector('[data-subjects]');
        if (!subjectsEl) {
            return;
        }

        gsap.to(subjectsEl, {
            opacity: 0,
            height: 0,
            duration: 0.3,
            onComplete() {
                subjectsEl.remove();
            }
        });
    };

    const update = (addLoadingClass = true) => {
        let url = `/${actionTrigger}/fhm/archive/search`;
        const searchQueryParams = new URLSearchParams();

        searchQuery = cleanQuery(searchQuery);
        if (searchQuery.length > 2) {
            searchQueryParams.set('query', searchQuery);
        }

        if (page > 1) {
            searchQueryParams.set('page', page);
        }

        if (museum.length && museum !== '*') {
            searchQueryParams.set('museum', museum);
        }

        if (subjects && subjects.length) {
            searchQueryParams.set('subjects', subjects);
        }

        const queryString = searchQueryParams.toString();
        if (queryString.length) {
            url = `${url}?${queryString}`;
        }

        let location = window.location.pathname;
        if (queryString.length) {
            location = `${location}?${queryString}`;

            showResetButton();
        } else {
            hideResetButton();
        }

        if (location === prevLocation) {
            return;
        }

        prevLocation = location;

        window.history.replaceState(null, '', location);

        if (abortController) {
            abortController.abort();
        }
        abortController = new AbortController();

        if (addLoadingClass) {
            el.classList.add('loading');
        }

        announce(announcements.loading || '');

        post(url, { signal: abortController.signal })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                return res.text();
            })
            .then(html => {
                const { height } = listing.getBoundingClientRect();
                gsap.timeline()
                    .to(listing, {
                        opacity: 0,
                        duration: 0.3
                    })
                    .add(() => {
                        Components.destroy(listing);
                        listing.innerHTML = html;
                        Components.init(listing);
                        const numArtifacts = listing.querySelectorAll('[data-doc]').length;
                        if (announcements.loaded) {
                            announce(announcements.loaded.replace('{count}', numArtifacts));
                        } else {
                            announce('');
                        }
                    })
                    .to(listing, {
                        opacity: 1,
                        duration: 0.3
                    }, 'in')
                    .fromTo(listing, { height }, {
                        height: 'auto',
                        duration: 0.3,
                        ease: 'Power2.easeInOut'
                    }, 'in');
            })
            .catch(error => {
                announce(announcements.error || '');
                console.error(error);
            })
            .finally(() => {
                abortController = null;
                el.classList.remove('loading');
                searchField.closest('.group')
                    .classList
                    .remove('loading');
            });
    };

    const removeSubject = subject => {
        const subjectsEl = subject.closest('[data-subjects]');
        if (!subjectsEl) {
            return;
        }

        if ((!document.activeElement || document.activeElement === subject) && !resetFiltersBtn.hidden) {
            resetFiltersBtn.focus({ preventScroll: true });
        }

        const numSubjects = subjectsEl.querySelectorAll('button').length;
        if (numSubjects === 1) {
            subjects = null;
            removeSubjects();
        } else {
            subjects = subjects
                .split(',')
                .reduce((carry, item) => !!item && item.trim() !== subject.textContent.trim() ? [...carry, item] : carry, [])
                .join(',');
            if (!subjects.length) {
                subjects = null;
            }
            subject.remove();
        }

        update();
    };

    const reset = () => {
        searchQuery = '';
        searchField.value = '';
        subjects = null;
        museumsListbox._listbox.reset();
        removeSubjects();

        update();
    };

    const onSearchFieldKeyUp = () => {
        const newSearchQuery = searchField.value.trim();
        if (newSearchQuery === searchQuery || (newSearchQuery.length > 0 && newSearchQuery.length < 3)) {
            return;
        }
        searchQuery = newSearchQuery;
        page = 1;

        searchField.closest('.group')
            .classList
            .add('loading');

        update();
    };

    const onSearchFieldKeyUpDebounced = debounce(onSearchFieldKeyUp, 250, {
        leading: false,
        trailing: true
    });

    const onMuseumsListboxChange = e => {
        const { value } = museumsListbox.querySelector('input[type="hidden"]');
        if (value === museum) {
            return;
        }
        museum = value;
        page = 1;

        update();
    };

    const onSubjectClick = e => {
        removeSubject(e.currentTarget);
    };

    const onResetFiltersButtonClick = () => {
        reset();
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);

        searchField.addEventListener('keyup', onSearchFieldKeyUpDebounced);
        if (museumsListbox) {
            museumsListbox.addEventListener('change', onMuseumsListboxChange);
        }

        resetFiltersBtn.addEventListener('click', onResetFiltersButtonClick);

        const subjects = el.querySelectorAll('[data-subjects] button');
        subjects.forEach(subject => {
            subject.addEventListener('click', onSubjectClick);
        });

        update(false);
    };

    const destroy = () => {
        searchField.removeEventListener('keyup', onSearchFieldKeyUpDebounced);
        if (museumsListbox) {
            museumsListbox.removeEventListener('change', onMuseumsListboxChange);
        }

        resetFiltersBtn.removeEventListener('click', onResetFiltersButtonClick);

        const subjects = el.querySelectorAll('[data-subjects] button');
        subjects.forEach(subject => {
            subject.removeEventListener('click', onSubjectClick);
        });
    };

    return {
        init,
        destroy
    };

};
