import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Config from '../core/Config';

/**
 * @returns {number}
 */
export const getMaxScroll = () => (Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - Viewport.height);

/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @type {MediaQueryList}
 */
export const reduceMotionQuery = window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)');

/**
 * @param timeline
 * @param clearProps
 */
export const clearTimelineProps = (timeline, clearProps = 'all') => {
    const children = timeline.getChildren();
    children.forEach(child => {
        if (typeof child.targets === 'function') {
            gsap.set(child.targets(), { clearProps });
        } else {
            clearTimelineProps(child, clearProps);
        }
    });
};

/**
 * @param rgbString
 * @param opacity
 * @returns {*}
 */
export const rgbToRgba = (rgbString, opacity) => rgbString.replace(
    /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/,
    `rgba($1, $2, $3, ${opacity})`
);

/**
 * @param value
 * @param props
 * @returns {any}
 */
export const t = (value, props = {}) => {
    const i18n = Config.get('i18n', {});
    let text = i18n[value] || value;
    Object.keys(props).forEach(key => {
        text = text.replaceAll(`{${key}}`, props[key]);
    });
    return text;
};

/**
 * @returns {boolean}
 */
export const isLivePreview = () => document.documentElement.classList.contains('is-preview') && document.documentElement.classList.contains('is-iframe');

/**
 *
 * @type {{customEvent: plausible.customEvent}}
 */
export const plausible = {
    customEvent: event => {
        if (!event || !window.plausible) {
            return;
        }
        console.info('Track custom event', { event });
        try {
            window.plausible(event);
        } catch (error) {
            console.error(error);
        }
    }
};
