import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from '../lib/events';
import { loadBricks } from '../lib/async-bundles';
import Viewport from '../core/Viewport';

export default (el, props) => {

    const { sizes } = props;

    let bricks = null;
    let mutationObserver;

    const pack = () => {
        el.style = '';
        el.querySelectorAll('[data-packed]').forEach(packed => {
            packed.style = '';
        });
        bricks.pack();

        el.querySelectorAll('[data-x-reveal]').forEach(node => {
            node.setAttribute('data-reveal', node.getAttribute('data-x-reveal'));
            node.removeAttribute('data-x-reveal');
        });

        el.dispatchEvent(new CustomEvent('packed', { bubbles: true }));
        Dispatch.emit(DOM_CHANGED);
    };

    const onResize = () => {
        if (!bricks) {
            return;
        }
        pack();
    };

    const init = () => {
        loadBricks(({ default: Bricks }) => {
            bricks = Bricks({
                container: el,
                packed: 'data-packed',
                sizes
            });
            pack();
        });

        mutationObserver = new MutationObserver(mutationsList => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList') {
                    pack();
                }
            });
        });
        mutationObserver.observe(el, { childList: true });

        Viewport.on('resize', onResize);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('resize', onResize);

        if (mutationObserver) {
            mutationObserver.disconnect();
            mutationObserver = null;
        }
    };

    return {
        init,
        destroy
    };

};
