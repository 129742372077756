import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT, DOM_CHANGED } from './events';

const REWIND_REVEALS = false;
const DEFAULT_STAGGER = 100;

const timeouts = new WeakMap();
let tweens = new WeakMap();

let nodes = [];
let observer;
let intersecting = [];

const getOptions = el => {
    const value = el.dataset.reveal;
    try {
        return JSON.parse(value);
    } catch (error) {}
    return {
        type: value
    };
};

const getTween = el => {

    let tween = tweens.get(el);

    if (tween) {
        return tween;
    }

    el.setAttribute('data-revealed', true);

    const options = getOptions(el) || {};
    const { type } = options;

    let { delay } = options;
    if (delay !== undefined) {
        delay = parseFloat(delay);
    }

    tween = gsap.timeline({
        paused: true,
        onStart() {
            el.setAttribute('data-revealing', true);
        },
        onReverseComplete() {
            el.removeAttribute('data-revealing');
        },
        onComplete() {
            el.removeAttribute('data-revealing');
        },
        delay
    });

    if (type === 'y') {
        tween
            .fromTo(el, { opacity: 0.001 }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeInOut'
            }, 0)
            .fromTo(el, {
                y: '5vmin',
                ...(options.from || {})
            }, {
                y: 0,
                ease: 'Quint.easeOut',
                duration: 1.5,
                ...(options.to || {}),
                immediateRender: false
            }, 0);
    } else {
        // Default is fade
        tween
            .fromTo(el, {
                opacity: 0.001,
                ...(options.from || {})
            }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Sine.easeInOut',
                ...(options.to || {})
            }, 0);
    }

    tween.set(el, { clearProps: 'transform,opacity,transform' });

    if (el.hasAttribute('data-reveal-once')) {
        tween.play();
        el.removeAttribute('data-reveal');
        el.removeAttribute('data-revealed');
        el.removeAttribute('data-revealing');
        el.removeAttribute('data-reveal-once');
    }

    tweens.set(el, tween);

    return tween;

};

const trackNodes = () => {
    $('[data-reveal]:not([data-revealed])')
        .each(node => {
            observer.observe(node);
            nodes.push(node);
        });
};

const onObserve = entries => {

    entries.forEach(entry => {

        const {
            target,
            isIntersecting
        } = entry;

        // Get tween
        const tween = getTween(target);
        if (!tween) {
            return;
        }

        let timeout = timeouts.get(target);
        if (timeout) {
            clearTimeout(timeout);
            timeouts.delete(target);
        }

        if (isIntersecting || target.hasAttribute('data-reveal-always')) {
            intersecting.push(target);
            // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
            intersecting = nodes.filter(node => intersecting.indexOf(node) > -1);
        } else {
            intersecting = intersecting.filter(node => node !== target);
        }

        const { top } = target.getBoundingClientRect();

        if (!isIntersecting && top >= Viewport.height) {
            // Reset the effect?
            if (REWIND_REVEALS) {
                tween.pause(0, false);
            }
            return;
        }

        // Calculate base stagger
        let stagger = target.dataset.revealStagger;
        if (stagger === undefined) {
            stagger = DEFAULT_STAGGER;
        }
        stagger = parseInt(stagger, 10);

        if (!isIntersecting && top < 0) {

            tween.pause(tween.duration(), false);

        } else if (isIntersecting && !tween.progress()) {

            stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node)
                .progress() <= 0.05)
                .indexOf(target));

            if (!stagger) {
                tween.play();
                return;
            }

            timeout = setTimeout(() => {
                clearTimeout(timeout);
                timeouts.delete(target);
                requestAnimationFrame(() => {
                    tween.play();
                });
            }, stagger);
        }

    });

};

const createObserver = () => {
    observer = new IntersectionObserver(onObserve, {
        threshold: [0, 0.5, 1],
        rootMargin: '0% 0% 0% 0%'
    });
};

const update = () => {

    if (!observer) {
        return;
    }

    // Unobserve nodes that are no longer in the DOM
    nodes = nodes.reduce((carry, node) => {
        if (node.closest('html')) {
            return carry.concat(node);
        }
        observer.unobserve(node);
        const tween = tweens.get(node);
        if (tween) {
            tweens.delete(node);
            tween.kill();
        }
        const timeout = timeouts.get(node);
        if (timeout) {
            timeouts.delete(node);
            clearTimeout(timeout);
        }
        return carry;
    }, []);

    trackNodes();
};

let hasInited = false;

const init = () => {

    if (hasInited) {
        return;
    }

    hasInited = true;

    createObserver();

    trackNodes();

    Viewport.on('breakpoint', update);

    Dispatch.on(DOM_CHANGED, update);

    if (ENV !== 'production') {
        setTimeout(() => {
            Dispatch.on(COMPONENT_INIT, () => {
                setTimeout(update, 0);
            });
        }, 0);
    }

};

const destroy = () => {
    if (observer) {
        observer.disconnect();
        observer = null;
    }
    nodes.forEach(node => {
        const tween = tweens.get(node);
        if (tween) {
            tween.progress(1)
                .kill();
        }
        node.removeAttribute('data-revealed');
    });
    nodes = [];
    tweens = new WeakMap();
    hasInited = false;

    Viewport.off('breakpoint', update);

    Dispatch.off(DOM_CHANGED, update);
};

export default ({
    init,
    update,
    destroy,
    tweens
});
