import { loadFuse } from '../lib/async-bundles';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default (el, props) => {

    const searchField = el.querySelector('input[name="query"]');
    const listing = document.getElementById('listing');
    const groups = [...el.querySelectorAll('[data-group]')];
    const items = [...listing.querySelectorAll('[data-item]')];
    const announcer = document.getElementById('announcer');
    const noResultsMessage = el.querySelector('[data-noresults]');

    const { announcements } = props;

    const master = items.map(item => ({
        node: item,
        name: item.dataset.name,
        role: item.dataset.role,
        department: item.dataset.department
    }));

    let lastQuery = '';
    let fuse = null;

    const reset = () => {
        [...groups, ...items].forEach(item => {
            item.hidden = false;
        });

        announcer.textContent = announcements.reset.replace('{count}', items.length);
        noResultsMessage.hidden = true;
    };

    const search = () => {
        if (!fuse) {
            return;
        }
        const query = searchField.value.trim();
        if (query === lastQuery) {
            return;
        }
        lastQuery = query;
        if (!query.length) {
            reset();
            return;
        }

        const raw = fuse.search(query);
        const result = raw.map(r => r.item.node);

        items.forEach(item => {
            item.hidden = result.indexOf(item) === -1;
        });

        groups.forEach(group => {
            group.hidden = !group.querySelector('[data-item]:not([hidden])');
        });

        if (result.length) {
            const numVisible = listing.querySelectorAll('[data-item]:not([hidden])').length;
            announcer.textContent = announcements.results.replace('{count}', numVisible).replace('{query}', lastQuery);
            noResultsMessage.hidden = true;
        } else {
            announcer.textContent = announcements.noResults.replace('{query}', lastQuery);
            noResultsMessage.hidden = false;
        }
    };

    const onSearchFieldKeyUp = () => {
        search();
    };

    const init = () => {
        loadFuse(({ default: Fuse }) => {
            fuse = new Fuse(master, {
                includeScore: true,
                shouldSort: true,
                threshold: 0.2,
                ignoreLocation: true,
                keys: ['name', 'role', 'department']
            });

            search();
        });

        searchField.addEventListener('keyup', onSearchFieldKeyUp);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        searchField.removeEventListener('keyup', onSearchFieldKeyUp);
    };

    return {
        init,
        destroy
    };

};
