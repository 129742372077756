/*
 *   This component is adapted from https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
 *   https://www.w3.org/copyright/software-license-2023/
 */

import gsap from 'gsap';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import { clearTimelineProps, reduceMotionQuery } from '../lib/helpers';

export default el => {

    let select;

    // Save a list of named combobox actions, for future readability
    const SelectActions = {
        Close: 0,
        CloseSelect: 1,
        First: 2,
        Last: 3,
        Next: 4,
        Open: 5,
        PageDown: 6,
        PageUp: 7,
        Previous: 8,
        Select: 9,
        Type: 10
    };

    /*
     * Helper functions
     */

    // filter an array of options against an input string
    // returns an array of options that begin with the filter string, case-independent
    function filterOptions(options = [], filter, exclude = []) {
        return options.filter(option => {
            const matches = option.toLowerCase()
                .indexOf(filter.toLowerCase()) === 0;
            return matches && exclude.indexOf(option) < 0;
        });
    }

    // map a key press to an action
    function getActionFromKey(event, menuOpen) {
        const {
            key,
            altKey,
            ctrlKey,
            metaKey
        } = event;
        const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
        // handle opening when closed
        if (!menuOpen && openKeys.includes(key)) {
            return SelectActions.Open;
        }

        // home and end move the selected option when open or closed
        if (key === 'Home') {
            return SelectActions.First;
        }
        if (key === 'End') {
            return SelectActions.Last;
        }

        // handle typing characters when open or closed
        if (key === 'Backspace' || key === 'Clear' || (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)) {
            return SelectActions.Type;
        }

        // handle keys when open
        if (menuOpen) {
            if (key === 'ArrowUp' && altKey) {
                return SelectActions.CloseSelect;
            }
            if (key === 'ArrowDown' && !altKey) {
                return SelectActions.Next;
            }
            if (key === 'ArrowUp') {
                return SelectActions.Previous;
            }
            if (key === 'PageUp') {
                return SelectActions.PageUp;
            }
            if (key === 'PageDown') {
                return SelectActions.PageDown;
            }
            if (key === 'Escape') {
                return SelectActions.Close;
            }
            if (key === 'Enter' || key === ' ') {
                return SelectActions.CloseSelect;
            }
        }

        return null;
    }

    // return the index of an option from an array of options, based on a search string
    // if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
    function getIndexByLetter(options, filter, startIndex = 0) {
        const orderedOptions = [
            ...options.slice(startIndex),
            ...options.slice(0, startIndex)
        ];
        const firstMatch = filterOptions(orderedOptions, filter)[0];
        const allSameLetter = array => array.every(letter => letter === array[0]);

        // first check if there is an exact match for the typed string
        if (firstMatch) {
            return options.indexOf(firstMatch);
        }

        // if the same letter is being repeated, cycle through first-letter matches
        if (allSameLetter(filter.split(''))) {
            const matches = filterOptions(orderedOptions, filter[0]);
            return options.indexOf(matches[0]);
        }

        // if no matches, return -1
        return -1;
    }

    // get an updated option index after performing an action
    function getUpdatedIndex(currentIndex, maxIndex, action) {
        const pageSize = 10; // used for pageup/pagedown

        switch (action) {
            case SelectActions.First:
                return 0;
            case SelectActions.Last:
                return maxIndex;
            case SelectActions.Previous:
                return Math.max(0, currentIndex - 1);
            case SelectActions.Next:
                return Math.min(maxIndex, currentIndex + 1);
            case SelectActions.PageUp:
                return Math.max(0, currentIndex - pageSize);
            case SelectActions.PageDown:
                return Math.min(maxIndex, currentIndex + pageSize);
            default:
                return currentIndex;
        }
    }

    // check if element is visible in browser view port
    function isElementInView(element) {
        const bounding = element.getBoundingClientRect();

        return (
            bounding.top >= 0
            && bounding.left >= 0
            && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
            && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    // check if an element is currently scrollable
    function isScrollable(element) {
        return element && element.clientHeight < element.scrollHeight;
    }

    // ensure a given child element is within the parent's visible scroll area
    // if the child is not visible, scroll the parent
    function maintainScrollVisibility(activeElement, scrollParent) {
        const {
            offsetHeight,
            offsetTop
        } = activeElement;
        const {
            offsetHeight: parentOffsetHeight,
            scrollTop
        } = scrollParent;

        const isAbove = offsetTop < scrollTop;
        const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

        if (isAbove) {
            scrollParent.scrollTo(0, offsetTop);
        } else if (isBelow) {
            scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
        }
    }

    /*
     * Select Component
     * Accepts a combobox element and an array of string options
     */
    // eslint-disable-next-line no-shadow,func-names
    const Select = function (el) {
        // element refs
        this.el = el;
        this.comboEl = this.el.querySelector('[role=combobox]');
        this.labelEl = document.getElementById(`${this.el.id}-label`);
        this.listboxEl = this.el.querySelector('[role=listbox]');
        this.optionsContainerEl = this.listboxEl.querySelector('[data-options]');
        this.optionEls = this.optionsContainerEl.querySelectorAll('[role="option"]');
        this.options = [...this.optionEls].map(optionEl => optionEl.textContent.trim());
        this.inputEl = this.el.querySelector('input[type="hidden"]');

        // state
        this.activeIndex = [...this.optionEls].indexOf(el.querySelector('[role="option"][aria-selected="true"]'));
        this.open = false;
        this.searchString = '';
        this.searchTimeout = null;

        this.init();
    };

    Select.prototype.onBodyClickOrFocus = function (e) {
        if (!this.open || e.target === this.comboEl || this.comboEl.contains(e.target) || e.target === this.listboxEl || this.listboxEl.contains(e.target)) {
            return;
        }
        this.updateMenuState(false, true);
    };

    Select.prototype.init = function () {
        this.labelEl.addEventListener('click', this.onLabelClick.bind(this));
        this.comboEl.addEventListener('blur', this.onComboBlur.bind(this));
        this.listboxEl.addEventListener('focusout', this.onComboBlur.bind(this));
        this.comboEl.addEventListener('click', this.onComboClick.bind(this));
        this.comboEl.addEventListener('keydown', this.onComboKeyDown.bind(this));
        this.comboEl.addEventListener('keyup', this.onComboKeyUp.bind(this));
        this.optionsContainerEl.addEventListener('click', this.onOptionsContainerClick.bind(this));

        document.body.addEventListener('click', this.onBodyClickOrFocus.bind(this));
        document.body.addEventListener('focusin', this.onBodyClickOrFocus.bind(this));
    };

    Select.prototype.destroy = function () {
        this.labelEl.removeEventListener('click', this.onLabelClick.bind(this));
        this.comboEl.removeEventListener('blur', this.onComboBlur.bind(this));
        this.listboxEl.removeEventListener('focusout', this.onComboBlur.bind(this));
        this.comboEl.removeEventListener('click', this.onComboClick.bind(this));
        this.comboEl.removeEventListener('keydown', this.onComboKeyDown.bind(this));
        this.comboEl.removeEventListener('keyup', this.onComboKeyUp.bind(this));
        this.optionsContainerEl.removeEventListener('click', this.onOptionsContainerClick.bind(this));
        document.body.removeEventListener('click', this.onBodyClickOrFocus.bind(this));
        document.body.removeEventListener('focusin', this.onBodyClickOrFocus.bind(this));
    };

    Select.prototype.getSearchString = function (char) {
        // reset typing timeout and start new timeout
        // this allows us to make multiple-letter matches, like a native select
        if (typeof this.searchTimeout === 'number') {
            window.clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = window.setTimeout(() => {
            this.searchString = '';
        }, 500);

        // add most recent letter to saved search string
        this.searchString += char;
        return this.searchString;
    };

    Select.prototype.setActiveOption = function (option) {
        const options = [...this.optionEls];
        options.forEach(optionEl => {
            optionEl.classList.remove('current');
        });
        if (option) {
            option.classList.add('current');
        }
    };

    Select.prototype.reset = function () {
        this.searchString = '';
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = null;
        }
        this.setActiveOption(null);
        const defaultValue = this.el.dataset.default || null;
        if (defaultValue) {
            const allValues = [...this.optionEls].map(option => option.dataset.value || option.textContent.trim());
            this.selectOption(allValues.indexOf(defaultValue));
        } else {
            this.selectOption(null);
        }
    };

    Select.prototype.onLabelClick = function () {
        this.comboEl.focus();
    };

    Select.prototype.onComboBlur = function (event) {
        // do nothing if relatedTarget is contained within listboxEl
        if (this.listboxEl.contains(event.relatedTarget)) {
            return;
        }

        this.updateMenuState(false, false);
    };

    Select.prototype.onComboClick = function () {
        this.updateMenuState(!this.open, false);
    };

    Select.prototype.onComboKeyDown = function (event) {
        const { key } = event;
        const max = this.options.length - 1;

        const action = getActionFromKey(event, this.open);

        switch (action) {
            case SelectActions.Last:
            case SelectActions.First:
                this.updateMenuState(true);
            // intentional fallthrough
            case SelectActions.Next:
            case SelectActions.Previous:
            case SelectActions.PageUp:
            case SelectActions.PageDown:
                event.preventDefault();
                return this.onOptionChange(
                    getUpdatedIndex(this.activeIndex, max, action)
                );
            case SelectActions.CloseSelect:
                event.preventDefault();
                this.selectOption(this.activeIndex);
            // intentional fallthrough
            case SelectActions.Close:
                this.preventKeyUp = true;
                event.preventDefault();
                return this.updateMenuState(false);
            case SelectActions.Type:
                return this.onComboType(key);
            case SelectActions.Open:
                event.preventDefault();
                return this.updateMenuState(true);
        }
    };

    Select.prototype.onComboKeyUp = function (event) {
        if (this.preventKeyUp) {
            event.stopPropagation();
        }
        this.preventKeyUp = false;
    };

    Select.prototype.onComboType = function (letter) {
        // open the listbox if it is closed
        this.updateMenuState(true);

        // find the index of the first matching option
        const searchString = this.getSearchString(letter);
        const searchIndex = getIndexByLetter(
            this.options,
            searchString,
            this.activeIndex + 1
        );

        // if a match was found, go to it
        if (searchIndex >= 0) {
            this.onOptionChange(searchIndex);
            return;
        }

        // if no matches, clear the timeout and search string
        window.clearTimeout(this.searchTimeout);
        this.searchString = '';
    };

    Select.prototype.onOptionChange = function (index) {

        // update state
        this.activeIndex = index;

        // update active option styles
        const options = [...this.optionEls];
        this.setActiveOption(options[index]);

        // update aria-activedescendant
        this.comboEl.setAttribute('aria-activedescendant', options[index].id);

        // ensure the new option is in view
        if (isScrollable(this.listboxEl)) {
            maintainScrollVisibility(options[index], this.listboxEl);
        }

        // ensure the new option is visible on screen
        // ensure the new option is in view
        if (!isElementInView(options[index])) {
            options[index].scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }
    };

    Select.prototype.onOptionsContainerClick = function (e) {
        e.stopPropagation();
        const option = e.target.getAttribute('role') === 'option' ? e.target : e.target.closest('[role="option"]');
        const optionIndex = [...this.optionEls].indexOf(option);
        this.onOptionChange(optionIndex);
        this.selectOption(optionIndex);
        this.updateMenuState(false);
    };

    Select.prototype.selectOption = function (index) {

        const options = [...this.optionEls];
        const option = options[index] || null;

        // update state
        this.activeIndex = index;

        // update displayed value
        const label = this.comboEl.querySelector('[data-label]') || this.comboEl.firstElementChild;
        label.innerHTML = this.options[index] || this.comboEl.dataset.placeholder || '';

        const currentSelectedOption = this.listboxEl.querySelector('[aria-selected="true"]');
        const currentActiveIndex = currentSelectedOption ? options.indexOf(currentSelectedOption) : -1;

        // update aria-selected
        options.forEach(optionEl => {
            optionEl.setAttribute('aria-selected', 'false');
        });

        let value;
        if (index !== null && index > -1) {
            options[index].setAttribute('aria-selected', 'true');
            this.el.classList.add('has-value');
            value = option.dataset.value || option.textContent.trim();
        } else {
            value = '';
        }

        this.inputEl.value = value;

        // Clear error
        const errorNode = el.querySelector('.error');
        if (errorNode) {
            errorNode.textContent = '';
        }

        if (currentActiveIndex !== this.activeIndex) {
            this.el.dispatchEvent(new Event('change', {
                bubbles: true,
                detail: { value }
            }));
        }
    };

    Select.prototype.updateMenuState = function (open, callFocus = true) {
        if (this.open === open) {
            return;
        }

        if (open && this.comboEl.getAttribute('aria-disabled') === 'true') {
            return;
        }

        // update state
        this.open = open;

        // update aria-expanded and styles
        this.comboEl.setAttribute('aria-expanded', `${open}`);

        const marginTop = parseInt(window.getComputedStyle(this.listboxEl).marginTop.replace('px', ''), 10) * -1;

        if (open) {
            this.listboxEl.hidden = false;
            if (reduceMotionQuery.matches) {
                gsap.killTweensOf(this.listboxEl);
                gsap.set(this.listboxEl, { clearProps: 'height' });
            } else {
                gsap.timeline({
                    onComplete() {
                        clearTimelineProps(this);
                    }
                })
                    .set(this.listboxEl, { overflow: 'hidden' }, 0)
                    .fromTo(this.listboxEl, { height: marginTop }, { height: 'auto', duration: 0.3, ease: 'Power2.easeOut' }, 0);
            }
        } else {
            if (reduceMotionQuery.matches) {
                gsap.killTweensOf(this.listboxEl);
                gsap.set(this.listboxEl, { clearProps: 'height' });
                this.listboxEl.hidden = true;
            } else {
                const _this = this;
                gsap.timeline({
                    onComplete() {
                        _this.listboxEl.hidden = true;
                        clearTimelineProps(this);
                    }
                })
                    .set(this.listboxEl, { overflow: 'hidden' }, 0)
                    .to(this.listboxEl, { height: marginTop, duration: 0.3, ease: 'Power2.easeOut' }, 0);
            }
        }

        // update activedescendant
        let activeId = '';
        if (open) {
            const activeOption = [...this.optionEls][this.activeIndex] || null;
            if (activeOption) {
                activeId = activeOption.id;
                this.setActiveOption(activeOption);
            }
        }
        this.comboEl.setAttribute('aria-activedescendant', activeId);

        if (activeId === '' && !isElementInView(this.comboEl)) {
            this.comboEl.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest'
            });
        }

        // move focus back to the combobox, if needed
        if (callFocus) {
            this.comboEl.focus();
        }
    };

    Select.prototype.enable = function () {
        this.comboEl.removeAttribute('aria-disabled');
    };

    Select.prototype.disable = function () {
        this.comboEl.setAttribute('aria-disabled', 'true');
    };

    Select.prototype.setOptions = function (options) {
        this.reset();
        // Remove existing options
        this.listboxEl.querySelectorAll('[role="option"]').forEach(option => option.remove());
        // Add new options
        const optionTemplate = document.createElement('template');
        optionTemplate.innerHTML = this.el.querySelector('script[type="text/template"]').innerHTML;
        const _this = this;
        options.forEach((option, index) => {
            const optionEl = optionTemplate.content.firstElementChild.cloneNode(true);
            optionEl.id = `${optionEl.id}${index}`;
            optionEl.setAttribute('data-value', option.value);
            optionEl.firstElementChild.innerHTML = option.label;
            _this.optionsContainerEl.appendChild(optionEl);
        });
        this.optionEls = this.optionsContainerEl.querySelectorAll('[role="option"]');
        this.options = [...this.optionEls].map(optionEl => optionEl.textContent.trim());
        if (this.options.length) {
            this.enable();
        } else {
            this.disable();
        }
    };

    Select.prototype.getValue = function () {
        return this.inputEl.value;
    };

    Select.prototype.getSelectedOption = function () {
        return this.listboxEl.querySelector('[role="option"][aria-selected="true"]');
    };

    const init = () => {
        select = new Select(el);
        el._listbox = select;
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        select.reset();
        select.destroy();
        select = null;
    };

    return {
        init,
        destroy
    };

};
